const WhereToBuy = () => {
  return (
    <>
      <section className="relative py-10 lg:py-20 xl:py-[150px]" id="where-to-buy">
        <div data-aos="fade-up" className="container mx-auto px-2 md:px-0">
          <h2 className="uppercase font-bold text-white text-center text-[24px] sm:text-[30px] md:text-[40px] xl:text-[48px] mb-4 md:mb-7 xl:mb-[50px]">
            Where To Buy
          </h2>

          <div className="flex flex-wrap justify-between items-center">
            <div className="w-full lg:w-5/12 mb-5 lg:mb-0">
              <img src="/images/Web5.png" className="w-[80%] lg:w-auto mx-auto" alt="where-to-buy-img" />
            </div>
            <div className="w-full lg:w-6/12 flex flex-col gap-4">
              <p className="text-sm md:text-base text-gold font-medium text-center">C.A: 68eD7fdMVEqKDev9jChsBxtvg45XF2FKsnVRfDTpxCkK</p>

              <div className="grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-4">
                <a href="https://jup.ag/swap/68eD7fdMVEqKDev9jChsBxtvg45XF2FKsnVRfDTpxCkK-SOL" target="_blank" rel="noreferrer" className="block w-full text-center bg-gold text-black font-bold py-3 px-6 rounded-full hover:bg-black hover:text-gold hover:ring-1 hover:ring-gold transition duration-300 ease-in-out">
                  <span>Buy on Jupiter</span>
                </a>

                <a href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=68eD7fdMVEqKDev9jChsBxtvg45XF2FKsnVRfDTpxCkK" target="_blank" rel="noreferrer" className="block w-full text-center bg-gold text-black font-bold py-3 px-6 rounded-full hover:bg-black hover:text-gold hover:ring-1 hover:ring-gold transition duration-300 ease-in-out">
                  <span>Buy on Raydium</span>
                </a>

                <a href="https://solscan.io/address/68eD7fdMVEqKDev9jChsBxtvg45XF2FKsnVRfDTpxCkK" target="_blank" rel="noreferrer" className="block w-full text-center bg-gold text-black font-bold py-3 px-6 rounded-full hover:bg-black hover:text-gold hover:ring-1 hover:ring-gold transition duration-300 ease-in-out">
                  <span>View on Solscan</span>
                </a>

                <a href="https://dexscreener.com/solana/68eD7fdMVEqKDev9jChsBxtvg45XF2FKsnVRfDTpxCkK" target="_blank" rel="noreferrer" className="block w-full text-center bg-gold text-black font-bold py-3 px-6 rounded-full hover:bg-black hover:text-gold hover:ring-1 hover:ring-gold transition duration-300 ease-in-out">
                  <span>View on DexScreener</span>
                </a>
              </div>

              <div id="dexscreener-embed">
                <iframe
                  title='dexscreener'
                  src="https://dexscreener.com/solana/9nUnbUCEss61d7EXof9LqikTV8bc3oM2XSKdUwkL11WJ?embed=1&theme=dark&trades=0&info=0">
                </iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default WhereToBuy