import Icons from './Socials/Icons'

const Intro = () => {
  return (
    <>
      <section id='home' className="flex items-center justify-center">
        <img src='/images/Banner.jpg' width={1500} height={500} alt='$JACK' className="w-full" />
      </section>

      <div className="container mx-auto px-2 md:px-0 py-4">
        <p className="text-sm md:text-lg text-black font-medium text-center">C.A: 68eD7fdMVEqKDev9jChsBxtvg45XF2FKsnVRfDTpxCkK</p>

        <div className="flex justify-center items-center gap-x-2 py-2">
          <Icons size={20} color='secondary' padding='p-3' withListing={true} />
        </div>
      </div>
    </>
  )
}

export default Intro