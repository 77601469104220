import Icons from './Icons'

const Socials = () => {
  return (
    <section id='socials' className="py-10 md:py-16  lg:py-20 xl:py-[150px]">
      <div data-aos="fade-up" className="container mx-auto px-2 md:px-0">
        <div className="text-center flex flex-col justify-center items-center">
          <span className="inline-block text-base md:text-[24px] lg:text-[30px] font-medium text-gold capitalize leading-none mb-4 lg:mb-7">
            Find $JACK on social media
          </span>

          <div className="flex justify-center items-center gap-x-8">
            <Icons />
          </div>
        </div>
      </div>
    </section >
  )
}

export default Socials