import Icons from './Socials/Icons'

const Header = () => {
  return (
    <header className="bg-gold shadow-xl hidden md:block">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex gap-x-12 items-center relative">
          <a href="#home" className="text-xl font-semibold leading-6 text-black">Home</a>
          <a href="#intro" className="text-xl font-semibold leading-6 text-black">Intro</a>
          <a href="#who-is" className="text-xl font-semibold leading-6 text-black">Who is ?</a>
          <a href="#what-is" className="text-xl font-semibold leading-6 text-black">What is ?</a>
          <a href="#photo-gallery" className="text-xl font-semibold leading-6 text-black">Photo Gallery</a>
          <a href="#where-to-buy" className="text-xl font-semibold leading-6 text-black">Where To Buy</a>
        </div>

        <div className="flex justify-center items-center gap-x-2">
          <Icons size={18} color='secondary' padding='p-2' withListing={true} />
        </div>
      </nav>
    </header>
  )
}

export default Header