import './App.scss';
import 'aos/dist/aos.css';
import AOS from 'aos';

import Header from './Components/Header';
import Banner from './Components/Banner';
import Intro from './Components/Intro';
import WhoIs from './Components/WhoIs';
import WhatIs from './Components/WhatIs';
import PhotoGallery from './Components/PhotoGallery';
import Socials from './Components/Socials';
import WhereToBuy from './Components/WhereToBuy';
import Footer from './Components/Footer';
import { useEffect } from 'react';
import ReactAudioPlayer from 'react-modern-audio-player';

export default function App() {
  useEffect(() => {
    AOS.init();
  });

  return (
    <>
      <Header />
      <Banner />
      <Intro />
      <WhoIs />
      <WhatIs />
      <PhotoGallery />
      <WhereToBuy />
      <Socials />
      <Footer />



      <ReactAudioPlayer
        playList={[
          {
            id: 1,
            name: 'Anthem',
            writer: 'Jack',
            img: '/logo512.png',
            src: '/anthem.mp3',
          }
        ]}
        audioInitialState={{
          isPlaying: false,
          volume: 1,
          repeatType: 'ALL'
        }}
        activeUI={{
          playButton: true,
          playList: null,
          volume: true,
          volumeSlider: true,
          progress: 'bar'
        }}
        placement={{
          player: 'bottom',
          playList: 'bottom'
        }}
      />
    </>
  )
}