import clsx from "clsx";
import { FaTwitter, FaTelegramPlane, FaLink, FaChartBar } from "react-icons/fa";

const SOCIALS = [
  { title: 'Twitter', icon: FaTwitter, link: 'https://twitter.com/jackthegoatsol' },
  { title: 'Telegram', icon: FaTelegramPlane, link: 'https://t.me/jacksportal' },
  { title: 'DexScreener', icon: FaChartBar, link: 'https://dexscreener.com/solana/68eD7fdMVEqKDev9jChsBxtvg45XF2FKsnVRfDTpxCkK' },
  { title: 'Linktree', icon: FaLink, link: 'https://linktr.ee/jackonsol' },
]

const Socials = ({ size = 24, color = 'primary', padding = 'p-4', withListing = false }) => {
  return (
    <>
      {SOCIALS.map((social, index) => (
        <a
          key={index}
          href={social.link}
          target="_blank"
          rel="noreferrer"
          className={
            clsx(
              "rounded-full transition-all duration-300",
              padding,
              color === 'primary' && "text-white bg-gold  hover:bg-white hover:text-gold",
              color === 'secondary' && "text-gold bg-white  hover:bg-gold hover:text-white hover:ring-white hover:ring-2",
            )
          }
        >
          <social.icon size={size} />
        </a>
      ))}

      {withListing && (
        <a
          href='https://www.coingecko.com/en/coins/jack-the-goat'
          target="_blank"
          rel="noreferrer"
        >
          <img src='/listing/coingecko.png' alt='coingecko' className='max-h-9' />
        </a>
      )}
    </>
  )
}

export default Socials