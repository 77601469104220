const WhoIs = () => {
  return (
    <section id='who-is' className="py-10 md:py-16  lg:py-20 xl:py-[150px]">
      <div data-aos="fade-up" className="container mx-auto px-2 md:px-0">
        <div className="text-center flex flex-col justify-center items-center mb-[30px] lg:mb-16">
          <span className="inline-block text-base md:text-[24px] lg:text-[30px] font-medium text-black capitalize leading-none mb-4 lg:mb-7">
            Who is JACK?
          </span>

          <img src="/images/Logo.webp" alt="JACK" className="w-full max-w-xs rounded-full" />

          <h2 className="uppercase font-bold text-white text-[24px] sm:text-[30px] md:text-[40px] xl:text-[48px]">
            JACK is a French bourgeoisie GOAT <br /> who took a pill in solana.
          </h2>
        </div>
      </div>
    </section>
  )
}

export default WhoIs