const Intro = () => {
  return (
    <section className="py-10 md:py-16 lg:py-20 xl:py-[100px]" id="intro">
      <div data-aos="fade-up" className=" container mx-auto px-2 md:px-0">
        <div className="flex flex-wrap gap-y-6 md:gap-y-10 justify-between items-center">
          <div className="w-full lg:w-6/12 lg:pr-4 text-center md:text-start">
            <h2 className="uppercase font-bold text-gold text-[24px] sm:text-[30px] md:text-[40px] xl:text-[48px] mb-4 md:mb-7 xl:mb-[50px]">
              Intro To<span className="text-white"> $JACK</span>
            </h2>
            <p className="text-base sm:text-lg lg:text-2xl text-gold leading-[1.6] pb-4">We're living in an age where people idolize their favorite real-world heroes as gods walking among us, whether they be athletes, billionaires, or influencers. revolution!</p>
            <p className="text-base sm:text-lg lg:text-2xl text-gold leading-[1.6] pb-4">Keyboard warriors rush to every comment section they can find defending them as if their lives depended on it in fierce battles to establish who reigns supreme as... The G.O.A.T.</p>
            <p className="text-base sm:text-lg lg:text-2xl text-gold leading-[1.6] pb-8 xl:pb-[60px]">Why fight for nothing, when you can unite and have your fortunes changed for the better. Introducing Jack The G.O.A.T, the only greatest of all time!</p>
          </div>
          <div className="w-full lg:w-6/12 lg:pl-4">
            <img src="/images/Web4.png" className="w-[70%] max-w-[500px] mx-auto lg:w-auto" alt='Jack The G.O.A.T' />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Intro