const Footer = () => {
  return (
    <footer className="bg-gold shadow-xl flex justify-center items-center flex-col gap-2 py-10">
      <h4 className="text-black text-2xl">&copy; {new Date().getFullYear()} $JACK. All Rights Reserved.</h4>
      <h5 className="text-black text-sm">
        Images on the website are either copyrighted or free to use. Some of them are private and were never meant to be shared, due to celebrity privacy concerns.
      </h5>
    </footer>
  )
}

export default Footer