const PHOTO_GALLERY = [
  { src: '/photo-gallery/messi.jpg', alt: 'Leo Messi x $JACK', title: 'Sweaty guy  playing football on the street almost ruined my jacket', },
  { src: '/photo-gallery/cristiano.jpg', alt: 'Cristiano Ronaldo x $JACK', title: 'This guy blending in so much with the background, thought i was taking a pic by myself', },
  { src: '/photo-gallery/lebron.jpg', alt: 'LeBron James x $JACK', title: 'Grabbed me out of nowhere as i was coming back from a night out to my penthouse, disrespectful peasant', },
  { src: '/photo-gallery/khabib.jpg', alt: 'Khabib x $JACK', title: 'Had a firm grip, man was so excited to see me as he should be', },
  { src: '/photo-gallery/tom.jpg', alt: 'Tom Brady x $JACK', title: 'My limo driver wanted a selfie, last time i book him he was crying all the way how his wife left him', },
]

const PhotoGallery = () => {
  return (
    <>
      <section className="relative py-10 lg:py-20 xl:py-[150px]" id="photo-gallery">
        <div data-aos="fade-up" className="container mx-auto px-2 md:px-0">
          <h2 className="uppercase font-bold text-white text-center text-[24px] sm:text-[30px] md:text-[40px] xl:text-[48px] mb-4 md:mb-7 xl:mb-[50px]">
            $JACK's Recent Photos
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {PHOTO_GALLERY.map((photo, index) => (
              <div data-aos="fade-up" key={index} className="relative overflow-hidden flex items-center flex-col gap-2">
                <img src={photo.src} alt={photo.alt} className="w-full max-w-md rounded" />
                <h3 className="text-gold text-center py-2">
                  {photo.title}
                </h3>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default PhotoGallery