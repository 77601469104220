const WhatIs = () => {
  return (
    <section className="py-10 md:py-16 lg:py-20 xl:py-[100px]" id="what-is">
      <div data-aos="fade-up" className="container mx-auto px-2 md:px-0">
        <div className="flex flex-wrap gap-y-6 md:gap-y-10 justify-between items-center">
          <div className="w-full lg:w-6/12 lg:pr-4 text-center md:text-start">
            <h2 className="uppercase font-bold text-white text-[24px] sm:text-[30px] md:text-[40px] xl:text-[48px] mb-4 md:mb-7 xl:mb-[50px]">
              What is<span className="text-gold"> $JACK</span>
            </h2>
            <p className="text-base sm:text-lg lg:text-2xl text-white leading-[1.6] pb-4">
              Jack the Goat is an ambitious meme project, looking to build a kingdom on Solana with the help of our community. With a goal of making you laugh, at the heart, we also want to grow together so that we can all be bourgeoisies thanks to the G.O.A.T.
            </p>
            <p className="text-base sm:text-lg lg:text-2xl text-white leading-[1.6] pb-4">
              The foundations to making this happen is to follow us on X and join the fun in telegram to keep up to date with the latest news from Jack.
            </p>
            <p className="text-base sm:text-lg lg:text-2xl text-white leading-[1.6] pb-8 xl:pb-[60px]">
              Please keep in mind that anything is possible in the crypto meme space, so DYOR before investing like any other coin.
            </p>
          </div>

          <div className="w-full lg:w-6/12 lg:pl-4">
            <img src="/images/Web1.png" className="w-[70%] max-w-[500px] mx-auto lg:w-auto" alt='Jack The G.O.A.T' />
          </div>
        </div>
      </div>
    </section>
  )

}

export default WhatIs